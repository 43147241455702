export default [
  {
    title:
      'Executive Management now has access to review the business at a granular level at any point in time, as a strategic tool this is invaluable.',
    content:
      'The Cognizance dashboards freed up our senior resources and gave Sales Managers the ability to highlight the current performance on a granular level to be able to swiftly amend the current strategy to best capitalize on the identified growth opportunity.',
    customerName: 'Wutow Trading',
    customerTitle: 'Wutow managing director',
  },
  {
    title: 'Increased service levels to trade which leads to an increase in turnover & customer satisfaction and improved working capital management.',
    content:
      'With a live view of both current and forecasted future stockholding the sales force can better plan and implement ad hoc promotional activities based on the stock available while providing Management insight into stock holding, and stock health of the business. The sales teams has real time visibility into stock holding. The Client having access to the live stock dashboard also has a view of their current stock within our warehouse. This avoids continuous challenges with regard to Client queries focused on demand planning and stock holding.',
    customerName: 'Wutow Trading',
    customerTitle: 'Sales director',
  },
  {
    title: 'Highlighting margin erosion and identifying underlying drivers',
    content:
      'Total business performance is visible on a profitability dashboard by Client, Division, Category and sku. These metrics are measured versus previous periods. Margins and costs can also be tracked on a sku level. Costs are easily identified by region and town to specifically form a commercial argument with the Client in terms of required margin adjustments.',
    customerName: 'Wutow Trading',
    customerTitle: 'Wutow managing director',
  }
];
