import React from 'react';
import Card from './Card';

const CustomerCard = ({ customer }) => (
  <Card className="mb-8">
    <p className="text-xl font-semibold">{customer.title}</p>
    <p className="mt-6">{customer.content}</p>
      <div>
        <p>{customer.customerName}</p>
        <p className="mt-1 text-sm text-gray-600">{customer.customerTitle}</p>
      </div>
  </Card>
);

export default CustomerCard;
