import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import BuildABase from '../svg/BuildABase';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import AdditionalInsight from '../svg/AdditionalInsight';
import TicketImg from '../svg/Ticket';
import { Link } from 'gatsby';

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Actioning insights
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Data automatically mined and understood. Translated into monetary based, ACTIONABLE
            revenue opportunities.
          </p>
          <p className="mt-4 text-gray-600">Moving beyond analytics</p>
          <div className="hidden md:block">
            <a href="mailto:info@cognizance.vision?subject=Request%20for%20information%20on%20Revops&body=I'd%20like%20to%20learn%20more%20about%20Cognizance's%20RevOps!%0A%0AI%20work%20for%3A%20%0A%0AI%20want%20to%20know...">
              <Button className="text-sm mt-4">Contact us</Button>
            </a>
          </div>
        </div>

        <div className="lg:w-3/4">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="services" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Key services</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Link to={'/RevOps'} activeClassName="active">
              <Card className="mb-8 text-gray-800">
                <p className="font-semibold text-xl ">Enterprise data management</p>
                <p className="mt-4">
                  Automated large scale data management. Ingestion, cleansing and consolodation from
                  different sources.
                </p>
              </Card>
            </Link>
          </div>

          <div className="flex-1 px-3">
            <Link to={'/RevOps'}>
              <Card className="mb-8 text-gray-800">
                <p className="font-semibold text-xl">Insights to actions platform</p>
                <p className="mt-4">
                  Your data converted from classic insights to revenue opportunities. Creation,
                  delegation and management through our specialised system.
                </p>
              </Card>
            </Link>
          </div>

          <div className="flex-1 px-3">
            <Link to={'/RevOps'}>
              <Card className="mb-8 text-gray-800">
                <p className="font-semibold text-xl">Advanced analytics</p>
                <p className="mt-4">
                  Tailored, industry specific analytics, delivering real time, ready to go insights
                  for the whole team.
                </p>
              </Card>
            </Link>
          </div>
        </div>
      </div>
    </section>

    <SplitSection
      id="about"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Step 1: Build a base</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Consolidate and match different data sources to form one robust source of truth to work
            from. Automatically, ingest, cleanse and consolidate large data sets.
          </p>
        </div>
      }
      secondarySlot={<BuildABase />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Step 2: Bring action to your BI process
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Quantified revenue opportunities are delivered on a personalised action board. The most
            profitable revenue opportunities delivered daily.
          </p>
        </div>
      }
      secondarySlot={
        <div className="w-full">
          {/* Can I pass props??? */}

          <TicketImg />
        </div>
      }
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Step 3: Provide additional insight into your data
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Advanced analytics developed to provide immediate insghts to users. Handle enterprise
            scale datasets, maintain simplicity for the end users, providing an all in one solution.
          </p>
        </div>
      }
      secondarySlot={<AdditionalInsight />}
    />

    <section id="results" className="py-5 lg:pb-10 lg:pt-18">
      {' '}
      {/* changed from 20 40 48 */}
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Deliverables</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Opportunities at your fingertips</p>
              <p className="mt-4">
                All the insghts from your BI-tool, summed up. We deliver prioritised revenue
                opportunities as and when they occur to the team or individual that can action them.
              </p>
            </Card>
          </div>

          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Revenue growth</p>
              <p className="mt-4">
                Actioned revenue opportunities inadvertently lead to revenue growth and value chain
                effeciency.
              </p>
              <p className="mt-4">
                The opportunities that are presented are ones that would previosuly go unnoticed
                until a savvy BI-team member picks them up at the end of the week.
              </p>
            </Card>
          </div>

          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Improved internal efficiencies</p>
              <p className="mt-4">
                RevOps host of revenue opportunity types mean that we can align points of failure
                directly to a potential revenue opportunity, this starts a problem solving
                behavioural change and focuses attention to where it matters most.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>

    {/* <section id="contact" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}

    <section id="more" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">
          Insights for the week: Wutow Trading{' '}
        </LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map((customer) => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Get me started</h3>
      <p className="mt-8 text-xl font-light">Drop us an email and we'll get back to you ASAP.</p>
      <p className="mt-8">
        <a href="mailto:info@cognizance.vision?subject=Request%20for%20information%20on%20Revops&body=I'd%20like%20to%20learn%20more%20about%20Cognizance's%20RevOps!%0A%0AI%20work%20for%3A%20%0A%0AI%20want%20to%20know...">
          <Button size="xl">Contact us</Button>
        </a>
      </p>
    </section>
  </Layout>
);

export default Index;
